nifty.document.ready(function () {

    var modal_form = null;
    var modal_confirmed = false;

    $('.btn-confirm').on('click', function (e) {
        var event = e || window.event;
        event.preventDefault();
        $('#confirmation-modal .btn-confirm-final').attr("href", $(this).attr("href"));
        $('#confirmation-modal .modal-title').html($(this).attr("data-modal-title"));
        if ($(this).attr("data-confirm-type") == 'delete') {
            $('#confirmation-modal .btn-confirm-final').addClass("btn-red");
        }
        else {
            $('#confirmation-modal .btn-confirm-final').addClass("btn-blue");
        }
        $('#confirmation-modal p').html($(this).attr("data-modal-text"));
        $('#confirmation-modal').modal();
    });

    $('.form-confirm:not(.no-permission)').on('submit', function (e) {
        var event = e || window.event;
        modal_form = $(this);
        if (!modal_confirmed) {
            event.preventDefault();
            $('#confirmation-modal').modal();
        }
    });

    $('.confirmation-modal-btn').on('click', function () {
        modal_confirmed = true;
        modal_form.submit();
    });
});


nifty.document.ready(function () {

    $('.link-confirmation-modal-btn').on('click', function () {
        $('#link-confirmation-modal').modal('hide');
    });

    $('body').on('click', '.link-confirm:not(.no-permission):not(.nrp-disabled)', function (e) {
        var event = e || window.event;
        event.preventDefault();
        $('#link-confirmation-modal .link-confirmation-modal-btn').attr("href", $(this).attr("href"));
        $('#link-confirmation-modal').modal();
    });

});

let warning_delay = 30; // 2 minutes

// Display logged out warning and modal
$(document).ready(function () {
    if (window.session_expiry) {
        set_logged_out_modal_timer();
    }
    $('.modal').on('hide.bs.modal', function (e) {
        if($(this).hasClass('non-closable-modal')){
            e.preventDefault();
        }
    });
});

$(document).on('click', '#session-timeout-modal .modal-submit', function (e){
    // Session extend
    $.get("/session/extend");
    e.preventDefault();
    set_logged_out_modal_timer();
    $("#session-timeout-modal").modal("hide");
});

let set_logged_out_modal_timer = () => {
    if(window.location.href.indexOf("/auth/login") != -1){
        // Don't do anything if already on the login page
        return;
    }

    localStorage.setItem("session_expiry_date", String(Date.now() + (window.session_expiry*1000)));

    set_time_out_logged_out(window.session_expiry*1000);
    set_time_out_warning(window.session_expiry*1000);
}

function set_time_out_logged_out(session_expiry_var) {
    setTimeout(function () {
        if (Date.now() >= parseInt(localStorage.getItem("session_expiry_date"))) {
            $('form#hidden-logout-form').submit();
        }
        else {
            set_time_out_logged_out(parseInt(localStorage.getItem("session_expiry_date")) - Date.now());
        }
    }, session_expiry_var)
}

function set_time_out_warning(session_expiry_var) {
    setTimeout(function () {
        if (Date.now() >= (parseInt(localStorage.getItem("session_expiry_date")) - warning_delay*1000)) {
            // Show warning n minutes before logout
            $("#session-timeout-modal").modal("show");
        }
        else {
            set_time_out_warning(parseInt(localStorage.getItem("session_expiry_date")) - Date.now());
            set_time_out_logged_out(parseInt(localStorage.getItem("session_expiry_date")) - Date.now());
        }
    }, (session_expiry_var - warning_delay*1000));
}

window.set_logged_out_modal_timer = set_logged_out_modal_timer